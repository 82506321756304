import React from 'react';

import { Layout } from '../../components/layouts';
import { SEO } from '../../components/common';
import {
  ReviewBodySection,
  ReviewHeroSection,
} from '../../components/common';
import { useStaticQuery, graphql } from 'gatsby';
import { ReviewGrid } from '../../types/component';
import { SiteUrls } from '../../utils/constants';

const WoosaPillowReview: React.FC = () => {
  const {
    allReviews: { edges },
    allProducts,
  } = useStaticQuery(graphql`
    query {
      allReviews(
        filter: {
          product: { id: { eq: "bLmYFUBvTpH8LcHAmyXP" } }
          display: { eq: true }
        }
        sort: { fields: submittedOn, order: DESC }
      ) {
        edges {
          node {
            content
            display
            id
            media
            product {
              name
            }
            rating
            submittedOn
            user {
              displayPic
              firstName
              lastName
            }
          }
        }
      }
      allProducts(filter: { id: { eq: "bLmYFUBvTpH8LcHAmyXP" } }) {
        edges {
          node {
            id
            name
            rating {
              avg
              count
              total
            }
          }
        }
      }
    }
  `);

  const data: ReviewGrid[] = edges.map(
    ({
      node,
    }: {
      node: {
        user: {
          displayPic?: string;
          firstName: string;
          lastName: string;
        };
        rating: number;
        content: string;
        submittedOn: Date;
      };
    }) => {
      return {
        displayPic: node.user.displayPic,
        name: `${node.user.firstName} ${node.user.lastName}`,
        rating: node.rating,
        content: node.content,
        submittedOn: node.submittedOn,
      };
    }
  );
  const product = { ...allProducts.edges[0].node };
  const title = 'The Woosa Pillow Reviews';

  return (
    <Layout>
      <SEO title={title} />
      <ReviewHeroSection type="Pillow" product={product} title={title} />
      <ReviewBodySection
        data={data}
        title={title}
        product={product}
        button={{
          text: 'Shop The Woosa Pillow',
          url: SiteUrls.OriginalPillow,
        }}
      />
    </Layout>
  );
};

export default WoosaPillowReview;
